@tailwind base;
@tailwind components;
@tailwind utilities;
@import "animate.css";

.privacy-page li {
  padding-left: 0.5rem;
}
.privacy-page a {
  text-decoration: underline;
}

:root {
  --moje-header-height: 84px;
  --moje-color-orange: #f29100;
  --moje-color-text-light-gray: #bababa;
  --moje-color-magenta: #e30074;
  --moje-mobile-header-height: 56px;
}

.moje-card-title {
  @apply font-medium;
}
